import React, { useEffect, useState } from "react";
import { ArrowUpIcon, CheckCircleIcon, QuestionIcon } from "@chakra-ui/icons";
import {
  Highlight,
  StackDivider,
  Box,
  Heading,
  Flex,
  Button,
  ListIcon,
  List,
  Link,
  IconButton,
  Text,
  Code,
  SlideFade,
  Stack,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
} from "@chakra-ui/react";
import {
  accordionStates,
  buttonStates,
  colours,
  sizes,
} from "./components/themes";
import PortfolioBox from "./components/PortfolioBox";
import {
  FaInstagram,
  FaLaptop,
  FaLinkedin,
  FaSquareGithub,
  FaYoutube,
} from "react-icons/fa6";
import { SiLinktree, SiPrintables } from "react-icons/si";

const App = () => {
  const codeSnippets = [
    <Code mt={5} children="console.log('how can i help?');" />,
    <Code mt={5} colorScheme="red" children="System.out.println('yikes');" />,
    <Code mt={5} colorScheme="yellow" children="pnpm install lui" />,
  ];

  const [codeSnippet, setCodeSnippet] = useState(codeSnippets[0]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV != "development") {
      handleScroll("top");
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCodeSnippet(getCodeSnippet());
    }, 2000); //1e3
    return () => {
      clearTimeout(timer);
    };
  }, [codeSnippet, isVisible]);

  const getCodeSnippet = () => {
    return codeSnippets[Math.floor(Math.random() * codeSnippets.length)];
  };

  const handleScroll = (location) => {
    const element = document.getElementById(location);
    // console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Flex
        align="center"
        bg={colours.bg}
        position={[null, null, "fixed"]}
        width="100%"
        maxW={sizes.maxWidth}
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        zIndex={1}
      >
        {/* NAV */}
        <IconButton
          position="fixed"
          bottom={5}
          right={5}
          onClick={() => handleScroll("top")}
          _active={buttonStates.onActive}
          _focus={buttonStates.onFocus}
          icon={
            <ArrowUpIcon
              _hover={buttonStates.onHover}
              boxSize={10}
              borderRadius={5}
              color={colours.buttonText}
              backgroundColor={colours.button}
              p={1}
            />
          }
        />
        <Heading as="h2" color={colours.text} ml={5} my={[4, null]}>
          Lui Crowie
        </Heading>
        <Flex
          justifyContent={"center"}
          flexDirection="row"
          flexWrap={"wrap"}
          maxW={["100%", null]}
          mr={[null, null, 3]}
        >
          {["blurb", "about", "creds", "portfolio", "links", "contact"].map(
            (value) => {
              return (
                <Button
                  key={`#${value}`}
                  onClick={() => handleScroll(value)}
                  bg={colours.button}
                  color={colours.buttonText}
                  mx={2}
                  my={3}
                  py={5}
                  borderRadius={5}
                  _hover={buttonStates.onHover}
                  _active={buttonStates.onActive}
                  _focus={buttonStates.onFocus}
                >
                  {value.toUpperCase()}
                </Button>
              );
            }
          )}
        </Flex>
      </Flex>

      {/* HERO */}
      <Box
        id={"top"}
        fontSize="xl"
        color="white"
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          boxSize={["90%", "75%", "50%", "40%"]}
          maxW="460px"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Heading id="top" as="h1">
            Ciao, I'm Lui 👋🏽
          </Heading>

          {/* TODO - fix the transition to roll or change to like vscode layout */}
          <SlideFade in={codeSnippet}>{codeSnippet}</SlideFade>

          <Text mt={3} mb={5} lineHeight="10">
            <Highlight
              query={["curious", "disseminating"]}
              styles={{
                px: "1.5",
                pb: 1.5,
                pt: 0.3,
                color: colours.hightlightText,
                bg: colours.grey,
                borderRadius: "7px",
              }}
            >
              A dude curious about how everything works; disseminating one
              thought at a time.
            </Highlight>
          </Text>
          <Button
            bg={colours.button}
            maxW="150px"
            mt={2}
            color={colours.buttonText}
            _hover={buttonStates.onHover}
            _active={buttonStates.onActive}
            _focus={buttonStates.onFocus}
            onClick={() => handleScroll("contact")}
          >
            CONTACT ME
          </Button>
        </Flex>
      </Box>

      {/* BLURB */}
      <Box
        fontSize="xl"
        color={colours.text}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        margin="0 auto"
        alignItems="center"
        maxW={["95vw", "90vw", "800px", "800px", "1200px"]}
      >
        <Heading id="blurb" as="h2">
          BLURB
        </Heading>
        <Stack flexDirection="column" p={3} spacing={22} mt={3} mb={10}>
          <Heading
            as="h3"
            fontSize="x-large"
            textTransform="uppercase"
            color={colours.subTitle}
          >
            Professional, buzz-word bingo
          </Heading>
          <Text textAlign="justify">
            An Engineer (primarily software) developing, enhancing and
            introducing technology and ways of working underpinning the
            thought-to-customer delivery process through many avenues such as
            continuous integration, cloud utilisation and gumption to deliver
            the best solution no matter the feat.
          </Text>
          <Text textAlign="justify">
            Strong engineering professional who achieved a 1st with honours in
            Digital and Technology Solutions from Manchester Metropolitan
            University. Utilising a can-do attitude, the drive to produce
            quality deliverables and the ability to think outside the box to be
            a highly efficient and motivated resource where no task is too big
            or too small.
          </Text>
          <Heading
            as="h3"
            fontSize="x-large"
            textTransform="uppercase"
            color={colours.subTitle}
          >
            Less-formal, Human, non-linkedin
          </Heading>
          <Text textAlign="justify">
            A down-to-earth, straight-shooter of a dude who not only says it as
            it is but likes to delve into the understanding of why and how
            everything works. Automation, engineering (specifically physics,
            maths and technology) and big-picture thinking are the main driving
            factors. Being a tinker-at-heart, takes the time to get as close to
            the real deal in the many projects, hobbies and past-times in
            progress.
          </Text>
          <Text textAlign="justify">
            Isn't afraid to ask the real questions like{" "}
            <Text as="cite">"Is this what we're trying to achieve?"</Text>,{" "}
            <Text as="cite">"Why?"</Text>, to start again or even the big scary
            one, saying he doesn't know... but already be working on a game plan
            on how to get there. An endless pit of knowledge and vast experience
            ready to be tapped or applied.
          </Text>
        </Stack>
      </Box>

      {/* ABOUT */}
      <Box
        fontSize="xl"
        color={colours.text}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        margin="0 auto"
        alignItems="center"
        maxW={["95vw", "90vw", "800px", "800px", "1200px"]}
        mt={5}
        mb={10}
        p={2}
      >
        <Heading id="about" as="h2">
          ABOUT
        </Heading>
        <Text my={4}>
          Greater detail is covered in the
          <Button
            color={colours.button}
            variant="link"
            fontSize="20px"
            mx={2}
            _hover={buttonStates.onHoverHighlightedText}
            onClick={() => handleScroll("creds")}
            textDecoration="underline"
          >
            creds
          </Button>
          and the
          <Button
            color={colours.button}
            variant="link"
            fontSize="20px"
            mx={2}
            _hover={buttonStates.onHoverHighlightedText}
            onClick={() => handleScroll("portfolio")}
            textDecoration="underline"
          >
            portfolio
          </Button>
          sections below. One of the key takeaways should be is this doesn't
          include everything; I have extensive experience in many sectors,
          domains and applications.
        </Text>
        <Stack
          flexDirection={["column", "column", "row"]}
          p={3}
          spacing={10}
          mt={3}
          mb={5}
        >
          <Box w={["100%", "100%", "50%"]}>
            <Heading
              as="h3"
              fontSize="x-large"
              textTransform="uppercase"
              color={colours.subTitle}
              textAlign="center"
            >
              What I Do
            </Heading>
            <Text my={3}>Not ranked but primarily include:</Text>
            <List pl={[0, 3]} spacing={1}>
              {[
                "Backend Development (branching into full-stack)",
                "Consulting",
                "DevOps Methodologies",
                "System Design",
                "Cloud Engineering",
                "App/game Development",
                "Cyber Security (very recently)",
              ].map((entry) => {
                return (
                  <ListItem display="flex">
                    <ListIcon
                      as={CheckCircleIcon}
                      color="green.500"
                      display="block"
                      mt={1.5}
                    />
                    <Text>{entry}</Text>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box w={["100%", "100%", "50%"]}>
            <Heading
              as="h3"
              fontSize="x-large"
              textTransform="uppercase"
              color={colours.subTitle}
              textAlign="center"
            >
              What I get up to
            </Heading>
            <Text my={3}>Among many others, highlights include:</Text>
            <List pl={[0, 3]} spacing={1}>
              {[
                "Tinkering (electronics, building things from scratch, 3D design/printing etc.)",
                "Building apps, games, tools and scripting",
                "Gaming",
                "Travelling, hiking, exploring",
                "Reading",
                "Eating pizza",
              ].map((entry) => {
                return (
                  <ListItem display="flex">
                    <ListIcon
                      as={QuestionIcon}
                      color="green.500"
                      display="block"
                      mt={2}
                    />
                    <Text>{entry}</Text>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Stack>
      </Box>

      {/* CREDS */}
      <Box
        fontSize="xl"
        color={colours.text}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        margin="0 auto"
        alignItems="center"
        maxW={["95vw", "90vw", "85vw", "1000px", "1200px", "1500px"]}
        mt={5}
        mb={3}
        p={2}
      >
        <Heading id="creds" as="h2">
          CREDS
        </Heading>
        <Stack
          flexDirection={["column", "column", "column", "row"]}
          p={3}
          spacing={10}
          mt={3}
          mb={10}
        >
          <Accordion
            display="flex"
            flexDirection="column"
            // justifyContent="center"
            // defaultIndex={[1]} TODO - maybe set?
            w={["95vw", "90vw", "85vw", "1000px", "1200px", "1500px"]}
            maxW="1200px"
            allowMultiple
            // outline={"solid 3px white"}
            borderRadius="5px"
          >
            {[
              {
                title: "Certifications",
                innerSections: [
                  {
                    title: "AWS Certified SysOps Administrator - Associate",
                  },
                  {
                    title: "KCNA: Kubernetes and Cloud Native Associate",
                  },
                  { title: "AWS Certified Cloud Practitioner" },
                  { title: "Microsoft Certified: Azure Fundamentals" },
                ],
              },
              {
                title: "Experience",
                description: "(Most recent first)",
                innerSections: [
                  {
                    title: "Consulting",
                    description: "Currently consulting via contracts @ CROWZFX",
                  },
                  {
                    title: "Gambling > Poker",
                    description:
                      "2+ years @ PokerStars as Principal DevOps Engineer",
                  },
                  {
                    title: "Research Science",
                    description: "1 year @ Dotmatics as Senior DevOps Engineer",
                  },
                  {
                    title: "Finance > Banking",
                    description:
                      "5+ years @ Lloyds Banking Group as Team Lead/Senior Software Engineer",
                  },
                ],
              },
              {
                title: "Skills",
                innerSections: [
                  {
                    title: "Extensive usage, thoroughly skilled",
                    description:
                      "Containerisation, Java, Java EE (IBM WAS Liberty), Groovy, Unix/Linux, Shell, Bash, DevOps (CI, CD, Orchestration tools like Jenkins, GitHub Actions, Route-to-Live, Package management), IaC (Terraform, Ansible, Jenkins) Kubernetes, Helm, Hashicorp Vault, Microservices (Spring, API's), Maven, Gradle, Kafka, IBM MQ, Databases (PostGres, DB2, Oracle, MySQL/MariaDB, SQLite), Git, IoT, SQL, Python, Cloud (AWS, IBM Cloud Private, Digital Ocean, Vercel), Edge Computing, CDNs, Networking (FWs, routing, VPCs etc.)",
                  },
                  {
                    title: "Less extensive, thoroughly skilled",
                    description:
                      "Javascript/Typescript, React, Flutter, Dart, Firebase, Supabase, Android App Development",
                  },
                  {
                    title: "Newest, least skilled",
                    description:
                      "AI/ML (building models from scratch, dealing with datasets etc.), Cyber Security, Electronics & Engineering, Hardware/Low-level Programming, Prototyping/Breadboarding",
                  },
                ],
              },
            ].map((card) => {
              return (
                <AccordionItem borderTop="revert" borderBottom="revert" my={2}>
                  <AccordionButton
                    bg={colours.altColour}
                    _hover={accordionStates.onHover}
                    borderRadius="8px"
                    py={4}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Heading
                        fontSize="xl"
                        as="h3"
                        textAlign="left"
                        textTransform="uppercase"
                      >
                        {card.title}
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {card.description ? (
                      <Text
                        fontSize="md"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {card.description}
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Stack
                      divider={<StackDivider bg={colours.bg} />}
                      spacing="3"
                    >
                      {card.innerSections.map((innerCard) => {
                        return (
                          <Box>
                            {innerCard.title ? (
                              <Heading
                                fontSize="md"
                                textTransform="uppercase"
                                as="h4"
                                my={2}
                              >
                                {innerCard.title}
                              </Heading>
                            ) : (
                              <></>
                            )}
                            {innerCard.description ? (
                              <Text
                                fontSize="large"
                                // textTransform="uppercase"
                                textAlign="justify"
                                my={2}
                              >
                                {innerCard.description}
                              </Text>
                            ) : (
                              <></>
                            )}
                          </Box>
                        );
                      })}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Stack>
      </Box>

      {/* PORTFOLIO */}
      <Box
        fontSize="xl"
        color={colours.text}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        margin="0 auto"
        alignItems="center"
        maxW={["95vw", "90vw", "85vw", "1000px", "1200px", "1500px"]}
      >
        <Heading id="portfolio" as="h2">
          PORTFOLIO
        </Heading>
        <Text mt={3} py={3} px={3}>
          Click each card to reveal more info; there are many more to be added,
          I'm slowly chipping away at it!
        </Text>
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          p={1}
          spacing={[1, 2, 3]}
          mt={5}
          mb={14}
        >
          <PortfolioBox
            title="McSniff"
            status="complete"
            link="https://github.com/crowz-fx/mcsniff"
            linkText="View on GitHub"
            tech={["Python", "Sockets"]}
            shortDescription="Python raw network analyser and packet sniffer without libraries"
            text={[
              "See README in repo for a deep dive.",
              "Nuts of it is a packet sniffer that processes and dumps the information converting raw-bytes into human-readable format.",
              "I wanted to deep-dive into what is being sent over my network on all the levels of the OSI model; there is no other way to learn than to tear it apart and reverse-engineer it.",
            ]}
          />
          <PortfolioBox
            title="System Info"
            status="live, on-going"
            link="https://play.google.com/store/apps/details?id=com.crowzfx.app.systeminfo"
            linkText="View on Play Store"
            tech={["Java", "Android"]}
            shortDescription="Android app giving you even more granular system info details"
            text={[
              "TLDR; I got fed up with all the apps on the app store not showing me all the network interfaces whether they are the current connection or not! So I decided to build my own app.",
              "Android app, written in barebones Java, so no libraries or framework outside of typical Android development, composing of fragments for views covering different system components such as networking, battery etc.",
            ]}
          />
          <PortfolioBox
            title="luicrowie.me"
            status="complete"
            tech={["JavaScript", "React", "Vercel", "Chakra-UI"]}
            shortDescription="A React website with Chakra-UI component library"
            text={[
              "The website you're currently looking at is a one-stop-shop collection of information and experience on me. Practically a CV that you can read to see if I'm your guy or not or maybe you want to see what I get up to!",
              "Another statically compiled website hosted on Vercel.",
            ]}
          />
          <PortfolioBox
            title="Sudoku Solver"
            status="complete"
            link="https://github.com/crowz-fx/sudoku_solver"
            linkText="View on GitHub"
            tech={["Python", "Backtracking", "PySide6 (Qt)"]}
            shortDescription="Python app utilising a backtracking algorithm to solve sudoku puzzles"
            text={[
              "As an avid 'sudoku-er' (not a word, I know), I wanted to learn how to solve one programmatically so, I built the solver in Python and also dabbled in some Qt to integrate a GUI.",
            ]}
          />
          <PortfolioBox
            title="intechlectual.co.uk"
            status="complete"
            link="https://intechlectual.co.uk"
            linkText="View Live"
            tech={["JavaScript", "React", "RSS", "Digital Ocean"]}
            shortDescription="React website processing our podcast RSS feed"
            text={[
              "A statically compiled website for the tech podcast that I co-produce called 'Intechlectual'.",
              "It breaks down the raw RSS feed from the podcast whilst also being a container for all our links and additional information.",
            ]}
          />
          <PortfolioBox
            title="p5js Sketches"
            status="on-going"
            link="https://crowz-fx.github.io/processing/"
            linkText="View on GitHub Pages"
            tech={["JavaScript", "p5js", "WebGL"]}
            shortDescription="A collection of processing projects/sketches developed over time"
            text={[
              "I have always been intrigued by graphics and visual representations of algorithms, so I started coding my own and bringing some of those ideas to life.",
            ]}
          />
        </Stack>
      </Box>

      {/* CONTACT */}
      <Box
        fontSize="xl"
        color={colours.text}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        margin="0 auto"
        textAlign="center"
        maxW={["95vw", "90vw", "800px", "800px", "1200px"]}
      >
        <Heading id="contact" as="h2">
          CONTACT
        </Heading>
        <Stack flexDirection="column" p={3} spacing={22} mt={3} mb={10}>
          <Text>Shoot me an email using</Text>
          <Link
            href="http://localhost:443/"
            textDecoration="underline"
            color={colours.altColour}
            _hover={{
              ...buttonStates.onHoverHighlightedText,
              textDecoration: "none",
              color: colours.text,
            }}
          >
            contact [at] crowzfx.co.uk
          </Link>
          <Text>
            Note - the link{" "}
            <strong>
              <i>IS</i>
            </strong>{" "}
            broken intentionally to throw a wrench at spam scalpers
          </Text>
        </Stack>
      </Box>

      {/* LINKS */}
      <Box
        fontSize="xl"
        color={colours.text}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        margin="0 auto"
        maxW={["95vw", "90vw", "800px", "800px", "1200px"]}
        p={2}
      >
        <Heading id="links" as="h2" mb={3}>
          LINKS
        </Heading>
        <Text>
          Looking to hire me or contact the company I run? see
          <Button
            color={colours.button}
            variant="link"
            fontSize="20px"
            mx={2}
            _hover={buttonStates.onHoverHighlightedText}
            onClick={() => (window.location.href = "https://crowzfx.co.uk")}
            textDecoration="underline"
          >
            CROWZFX
          </Button>
        </Text>
        <Stack flexDirection="column" p={3} spacing={19} mt={3} mb={10}>
          {[
            {
              title: "Personal",
              links: [
                {
                  text: "GitHub",
                  icon: FaSquareGithub,
                  link: "https://github.com/crowz-fx/",
                },
                {
                  text: "LinkedIn",
                  icon: FaLinkedin,
                  link: "https://www.linkedin.com/in/luicrowie",
                },
                {
                  text: "Instagram (private)",
                  icon: FaInstagram,
                  link: "https://www.instagram.com/_lifeoflui/",
                },
              ],
            },
            {
              title: "EngineeringWithLui",
              links: [
                {
                  text: "Linktree",
                  icon: SiLinktree,
                  link: "https://linktr.ee/engineeringwithlui",
                },
                {
                  text: "YouTube",
                  icon: FaYoutube,
                  link: "https://youtube.com/@engineeringwithlui",
                },
                {
                  text: "Printables (models & designs)",
                  icon: SiPrintables,
                  link: "https://www.printables.com/@engineeringwithlui",
                },
                {
                  text: "Instagram",
                  icon: FaInstagram,
                  link: "https://www.instagram.com/engineeringwithlui/",
                },
              ],
            },
            {
              title: "Intechlectual",
              links: [
                {
                  text: "Linktree",
                  icon: SiLinktree,
                  link: "https://linktr.ee/intechlectual",
                },
                {
                  text: "Website",
                  icon: FaLaptop,
                  link: "https://intechlectual.co.uk",
                },
              ],
            },
          ].map((linkBlock) => {
            return (
              <Box>
                <Heading
                  as="h3"
                  fontSize="x-large"
                  textTransform="uppercase"
                  color={colours.subTitle}
                  textAlign="center"
                  mb={4}
                  mt={4}
                >
                  {linkBlock.title}
                </Heading>

                <List>
                  {linkBlock.links.map((listElement) => {
                    return (
                      <ListItem mb={1}>
                        <Text>
                          <Link
                            _hover={{
                              ...buttonStates.onHoverHighlightedText,
                              textDecoration: "none",
                              color: colours.altColour,
                            }}
                            href={listElement.link}
                          >
                            <Icon as={listElement.icon} /> - {listElement.text}
                          </Link>
                        </Text>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export default App;
